<template>
  <section class="container">
    <iframe
      v-if="locale === 'de'"
      width="100%"
      src="https://monitor.irc-feedback.com/clientoutput/gb/1a171ebc26e22.php?css=v2"
    />
    <!-- en -->
    <iframe
      v-if="locale === 'en'"
      width="100%"
      src=" https://monitor.irc-feedback.com/clientoutput/gb/20293132914db.php?css=v2"
    />
    <!-- fr -->
    <iframe
      v-if="locale === 'fr'"
      width="100%"
      src="https://monitor.irc-feedback.com/clientoutput/gb/98c31329131a.php?css=v2"
    />
  </section>
</template>
<script setup lang="ts">
const { locale } = useI18n();
</script>

<style lang="postcss">
iframe {
  @apply h-[300px] lg:h-[500px];
}

body {
  font-family: 'Inter', serif;
  font-size: 18px;
  color: black;
}
.entry {
  padding: 20px 0;
  display: flex;
  flex-direction: column-reverse;
}
.entryname,
.entrytext {
  color: black;
  font-family: 'Inter';
}
.entryname {
  font-size: 18px;
}
.entrytext {
  font-size: 28px;
  line-height: 34px;
}

@media (max-width: 767px) {
  .entryname {
    font-size: 14px;
  }

  .entrytext {
    font-size: 18px;
    line-height: 20px;
  }
}
</style>
