<template>
  <section
    class="container grid-main"
    :class="data.backgroundColor === 'white' ? 'bg-white' : ''"
  >
    <!-- <AnimationsObserverFadeIn> -->
    <template v-if="data.list">
      <template v-for="(item, i) in data.list">
        <template v-if="item.type === 'file'">
          <div
            class="col-span-12 flex justify-between py-2 lg:py-4 border-b-[0.5px] mb-6 duration-300 hover:translate-x-1 cursor-pointer"
            :key="i"
            @click="downloadResource(item)"
          >
            <h4 class="lead">{{ item.name }}</h4>
            <span class="download-icon">
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.1875 38.1072L32.1875 14"
                  stroke="#333333"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22 28.0625L32 38.0626L42 28.0625"
                  stroke="#333333"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <line
                  x1="17.75"
                  y1="45.3125"
                  x2="48.25"
                  y2="45.3125"
                  stroke="#333333"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
        </template>
        <template v-if="item.type === 'image'">
          <div
            class="col-span-12 grid-main mb-6 cursor-pointer duration-300 hover:translate-x-1"
            :key="i"
            @click="downloadResource(item)"
          >
            <div class="col-span-12 lg:col-span-6">
              <Picture
                v-if="item.ref"
                :alt="item.ref.picture.alt"
                :isLazyloaded="item.ref.picture.isLazyloaded"
                :refImage="item.ref.picture.refImage._ref"
                :aspectRatios="[
                  { sm: 'square' },
                  { md: 'square' },
                  { lg: '16:9' },
                  { xl: '16:9' },
                  { '2xl': '16:9' },
                  { '3xl': '16:9' }
                ]"
              />
            </div>
            <div class="col-span-12 lg:col-span-6 flex flex-col justify-end">
              <div class="w-full flex justify-between py-4 border-b-[0.5px]">
                <h4 class="lead">{{ item.name }}</h4>

                <span class="download-icon">
                  <svg
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M32.1875 38.1072L32.1875 14"
                      stroke="#333333"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M22 28.0625L32 38.0626L42 28.0625"
                      stroke="#333333"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <line
                      x1="17.75"
                      y1="45.3125"
                      x2="48.25"
                      y2="45.3125"
                      stroke="#333333"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </template>
      </template>
    </template>
    <!-- </AnimationsObserverFadeIn> -->
  </section>
</template>

<script lang="ts" setup>
import { DownloadList, Resource } from '~/types';
import pkg from 'file-saver';
const { saveAs } = pkg;

interface DownloadListProps {
  data: DownloadList;
}

const { data } = defineProps<DownloadListProps>();

const downloadResource = (resource: Resource) => {
  if (!resource) {
    return;
  }

  let url = '',
    originalFilename = '';
  if (resource?.type === 'image' && resource.picture) {
    url = `${resource.picture?.upload.url}?dlRaw`;
    originalFilename = resource.picture?.upload.originalFilename;
  }

  if (resource?.type === 'file' && resource.file) {
    url = `${resource.file?.url}`;
    originalFilename = resource.file?.originalFilename;
  }

  downloadWithAuthorization(url, originalFilename);
};

const downloadWithAuthorization = async (url: string, originalFilename: string) => {
  const { data, error } = await useFetch<Blob>(url, {
    method: 'get',
    headers: {
      Authorization:
        'Bearer skslOb2RipO5xj2MVimX0wi7WdtumNmmr8m1MorPHW19mq4gsIJGpb7mqjUVsyZrEyvvn1Y1jvOWBRDhHL7JJZkADOZOdK24kSTKAzo4UMaALkckmUHDllqtqcRNvUPi3urfXB5QgurAFlDzH149iXu7FmJEDxKJKtuZsMZ0YBIxYjSgShHl'
    }
  });

  if (error.value) {
    throw new Error(error.value.message);
  }

  if (data.value) {
    saveAs(data.value, `${originalFilename}`);
  }
};
</script>
