<template>
  <section
    class="container grid-main list-2-columns"
    :class="data.backgroundColor === 'white' ? 'bg-white' : ''"
  >
    <div class="col-span-12 lg:col-span-6 mb-8 lg:mb-0">
      <h3 class="mb-4">{{ data.column1.title }}</h3>
      <ul>
        <li
          v-for="(item, i) in data.column1.list"
          :key="i"
        >
          <div class="richtext">
            <PortableText
              :value="item.infoText"
              :serializers="serializers"
            />
          </div>
        </li>
      </ul>
    </div>

    <div class="col-span-12 lg:col-span-6">
      <h3 class="mb-4">{{ data.column2.title }}</h3>
      <ul>
        <li
          v-for="(item, i) in data.column2.list"
          :key="i"
        >
          <div class="richtext">
            <PortableText
              :value="item.infoText"
              :serializers="serializers"
            />
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script setup lang="ts">
import { PortableText } from '@portabletext/vue';

interface List2ColumnsProps {
  data: {
    backgroundColor: string;
    column1: {
      title: string;
      list: {
        infoText: any[];
      }[];
    };
    column2: {
      title: string;
      list: {
        infoText: any[];
      }[];
    };
  };
}

const { data } = defineProps<List2ColumnsProps>();
const { serializers } = useSerializers();
</script>

<style lang="postcss">
.list-2-columns {
  ul {
    @apply list-none;
    li {
      @apply border-t-[0.3px] py-4 m-0;

      /* &:last-child {
        @apply border-b-[0.3px];
      } */
    }
  }

  .richtext {
    p {
      @apply mb-0;
    }
  }
}
</style>
