<template>
  <div
    ref="animationReference"
    class="animate-it"
  >
    <slot />
  </div>
</template>

<script setup>
const animationReference = ref(null);
useAnimate('onlyWhenScrollDown', animationReference);
</script>

<style lang="postcss" scoped>
/* ! Keep the postcss scoped ! */

.animate-it {
  transition: 0.5s;
  opacity: 0;
  transform: translate3d(0, 30px, 0);
}

/*
The class .animation-trigger is added on the element automatically when
it should be triggered. Just create your animation with CSS
*/
/* •·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•· */
/*                     Animation here                       */
/* •·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•·•· */

.animate-it.animation-trigger {
  opacity: 1 !important;
  transform: translate3d(0, 0, 0) skew(0) !important;
}
</style>
