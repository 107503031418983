export default function getRooms(locale: string) {
  const query = `live==true && _type=="room" && __i18n_lang=="${locale}" && !(_id in path("drafts.**"))`;

  return groq`{
    'documents': *[${query}] | order(orderRank asc) {
      detailPageSettings,
      category->,
      url,
      orderRank,
    },
    'roomCategories': *[_type == "roomCategory" && __i18n_lang=="${locale}" && !(_id in path("drafts.**"))]|order(orderRank)
  }`;
}
