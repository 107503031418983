<template>
  <section class="container lg:flex justify-center items-center md:justify-end">
    <template
      :key="index"
      v-for="(item, index) in data.list"
    >
      <Button
        class="w-full lg:w-auto mb-4 lg:mb-0 block md:ml-2"
        :variant="item.style"
        :to="item.button.isExternal ? item.button.externalLink : $fullpath(item.button.link._ref)"
        :isExternal="item.button.isExternal"
        :target="item.button.isExternal ? '_blank' : ''"
        >{{ item.button.label }}
      </Button>
    </template>
  </section>
</template>

<script setup lang="ts">
import { ButtonItem } from '~/types';

interface ButtonsProps {
  data: {
    list: ButtonItem[];
  };
}

defineProps<ButtonsProps>();

const { $fullpath } = useSiteSettings();
</script>

<style lang="postcss" scoped></style>
