<template>
  <section
    v-if="data.activatePressMediaList && pressMediaList"
    class="container"
  >
    <template v-for="(item, i) in list">
      <div class="grid-main border-b-[0.5px] py-4">
        <div class="hidden lg:col-span-3 lg:flex items-center">
          <p class="lead-2">{{ item.date }}</p>
        </div>
        <div class="col-span-7 lg:col-span-7 flex lg:items-center flex-col lg:flex-row">
          <p class="lg:hidden eyebrow py-0">{{ item.date }}</p>
          <p class="lead-2">{{ item.description }}</p>
        </div>
        <div class="col-span-5 lg:col-span-2 flex items-center justify-end lg:justify-start">
          <!-- External Link -->
          <template v-if="item.type === 'externalLink'">
            <a
              class="no-underline"
              :href="item.externalLink"
              target="_blank"
            >
              <ArrowLink
                :text="item.externalLinkLabel"
                :tag="'span'"
                :arrow-size="14"
              />
            </a>
          </template>
          <!-- File -->
          <template v-else>
            <SanityFile
              v-if="item.file && item.file.asset && item.file.asset._ref"
              :asset-id="item.file?.asset?._ref"
            >
              <template #default="{ src }">
                <a
                  class="no-underline"
                  :href="src"
                  target="_blank"
                >
                  <ArrowLink
                    :text="item.externalLinkLabel || $text('media-list-label') || 'Media'"
                    :tag="'span'"
                    :arrow-size="14"
                  />
                </a>
              </template>
            </SanityFile>
          </template>
        </div>
      </div>
    </template>

    <div class="w-full mt-8 flex justify-center">
      <button
        v-if="list.length !== pressMediaList.length"
        class="!w-fit btn secondary-normal"
        @click="loadItems"
      >
        {{ $text('media-list-button') }}
      </button>
    </div>
  </section>
</template>

<script setup lang="ts">
import getPressItemsQuery from '~/queries/getPressItemsQuery';

interface Press {
  date: string;
  description: string;
  externalLink: string;
  externalLinkLabel: string;
  type: 'externalLink' | 'file';
  file: { asset: { _ref: string } };
}

interface MediaListProps {
  data: {
    activatePressMediaList: boolean;
  };
}

const { data } = defineProps<MediaListProps>();
const sanity = useSanity();
const i18n = useI18n();
const query = getPressItemsQuery(i18n.locale.value);
const { data: pressMediaList } = await useAsyncData<Press[]>(() => sanity.fetch(query));

const { $text } = useSiteSettings();

const maxItems = 9;
const pages = ref<number>(0);
const startIndex = ref<number>(pages.value * maxItems);
const endIndex = ref<number>(startIndex.value + maxItems);
const list = ref<Press[]>(pressMediaList.value?.slice(startIndex.value, endIndex.value) || []);

const loadItems = () => {
  pages.value += 1;
  startIndex.value = pages.value * maxItems;
  endIndex.value = startIndex.value + maxItems;

  list.value = [
    ...list.value,
    ...(pressMediaList.value?.slice(startIndex.value, endIndex.value) || [])
  ];
};
</script>

<style lang="postcss" scoped></style>
