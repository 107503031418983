<template>
  <section
    class="overflow-hidden accordion-teaser"
    :class="data.backgroundColor === 'white' ? 'bg-white' : ''"
  >
    <AnimationsObserverFadeIn>
      <!-- lg:min-h-[calc(100vh-(theme(grid.gridXMargin)))] -->
      <div class="container grid-main xl:min-h-[700px]">
        <div
          class="flex flex-col col-start-1 col-end-13 md:col-end-5"
          :class="data.title ? 'md:justify-between' : 'justify-end'"
        >
          <!-- ArrowLink -->
          <NuxtLink
            class="no-underline mb-2"
            :to="$fullpath(data?.page?._ref)"
            v-if="data.isLink"
          >
            <ArrowLink
              v-if="data.title"
              :text="data.title"
              :tag="'h1'"
            />
          </NuxtLink>
          <h1
            class="mb-2"
            v-else
          >
            {{ data.title }}
          </h1>

          <!-- Accordion -->
          <div class="mt-2 md:mt-0">
            <AccordionList>
              <AccordionItem
                @click="selectItem(i)"
                v-for="(item, i) in data.list"
                :defaultOpened="i === 0"
                :key="i"
              >
                <template #summary>
                  <p class="subtitle my-2">{{ item.title }}</p>
                </template>
                <template #icon> </template>
                <SanityContent :blocks="item.text" />
                <Picture
                  class="flex md:hidden mt-4 w-full object-cover"
                  :alt="item.picture.alt"
                  :isLazyloaded="item.picture.isLazyloaded"
                  :refImage="item.picture.refImage._ref"
                  :aspectRatios="[
                    { sm: 'square' },
                    { md: 'square' },
                    { lg: '16:9' },
                    { xl: '16:9' },
                    { '2xl': '16:9' },
                    { '3xl': '16:9' }
                  ]"
                />
              </AccordionItem>
            </AccordionList>
            <Button
              v-if="data.button.label"
              class="mt-4 w-full"
              :to="
                data.button.isExternal ? data.button.externalLink : $fullpath(data.button.link._ref)
              "
              :isExternal="data.button.isExternal || false"
              :target="data.button.isExternal ? '_blank' : ''"
              variant="primary"
              variantType="normal"
              >{{ data.button.label }}
            </Button>
          </div>
        </div>

        <div class="col-start-5 col-end-13 relative overflow-hidden hidden md:flex">
          <template
            v-for="(item, i) in data.list"
            :key="i"
          >
            <Picture
              class="max-h-screen absolute top-0 left-0 w-full transition-all duration-700"
              :class="i === selectedItem ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-2'"
              :alt="item.picture.alt"
              :isLazyloaded="item.picture.isLazyloaded"
              :refImage="item.picture.refImage._ref"
              :aspectRatios="[
                { sm: 'square' },
                { md: 'square' },
                { lg: '867:722' },
                { xl: '867:722' },
                { '2xl': '867:722' },
                { '3xl': '867:722' }
              ]"
            />
          </template>
        </div>
      </div>
      <Separator v-if="data.bottomStroke?.isActive" />
    </AnimationsObserverFadeIn>
  </section>
</template>

<script setup lang="ts">
import 'vue3-rich-accordion/accordion-library-styles.css';
import { AccordionList, AccordionItem } from 'vue3-rich-accordion';
import { AccordionTeaser } from '~/types';

interface AccordionTeaserProps {
  data: AccordionTeaser;
}

const { data } = defineProps<AccordionTeaserProps>();

let selectedItem = ref(0);

const { $fullpath } = useSiteSettings();

const selectItem = (index: number) => {
  selectedItem.value = index;
};
</script>

<style lang="postcss">
.accordion-teaser {
  .accordion-item {
    @apply border-l-0 border-r-0 border-b-0 rounded-none my-0 bg-white;

    &:first-child {
      @apply !border-t-0;
    }
  }

  .accordion-item__summary {
    @apply !border-b-0 !bg-transparent !px-0;
  }
  .accordion-item__content {
    @apply !pt-0 !px-0;
  }
  .accordion-item--open {
    @apply bg-none pb-0;
    .subtitle {
      @apply text-green;
    }
  }

  summary::-webkit-details-marker {
    display: none;
    opacity: 0;
    visibility: hidden;
    width: 0;
    height: 0;
  }
}
</style>
