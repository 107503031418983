<script setup lang="ts">
import { SanityForm } from '~/types';
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';

interface FormProps {
  data: SanityForm;
}

const { data } = defineProps<FormProps>();
const { $fullpath } = useSiteSettings();
const router = useRouter();
const phoneNumber = ref();
const results = ref();

const submit = () => {
  // const { data: email } = useFetch('api/email', {
  //   method: 'POST'
  // });
  if (data.redirectPage) {
    router.push($fullpath(data.redirectPage._ref));
  }
  // if (email.value) {
  // }
};

const updateInputValue = (value: string) => {};

const updatePhoneNumber = (value: string) => {};
</script>

<template>
  <section class="container">
    <FormKit
      type="form"
      @submit="submit"
    >
      <template v-for="(input, i) in data.inputs">
        <template v-if="input.formItemType === 'input'">
          <FormInput
            :form-layout="data.formLayout"
            :input-type="input.inputType"
            :label="input.label"
            :placeholder="input.placeholder"
            :required="input.required"
            @update:input="($event) => updateInputValue($event)"
            @update:phone-number="($event) => updatePhoneNumber($event)"
          />
        </template>
        <template v-if="input.formItemType === 'textarea'">
          <div class="col-span-6">
            <FormKit
              :name="input.label"
              :type="input.formItemType"
              :placeholder="input.placeholder"
              :validation="input.required ? 'required' : ''"
              rows="10"
            />
          </div>
        </template>
        <template v-if="input.formItemType === 'select'">
          <div class="col-span-6">
            <FormKit
              :name="input.label"
              :type="input.formItemType"
              :placeholder="input.placeholder"
              :validation="input.required ? 'required' : ''"
              :options="input.options"
            />
          </div>
        </template>
        <template v-if="input.formItemType === 'checkbox'">
          <div class="col-span-6">
            <FormKit
              :name="input.label"
              type="checkbox"
              :placeholder="input.placeholder"
              :validation="input.required ? 'required' : ''"
              :options="input.values"
            />
          </div>
        </template>
        <template v-if="input.formItemType === 'radio'">
          <div class="col-span-6">
            <FormKit
              :name="input.label"
              :type="input.formItemType"
              :placeholder="input.placeholder"
              :validation="input.required ? 'required' : ''"
              :options="input.values"
            />
          </div>
        </template>
        <template v-if="input.formItemType === 'datePicker'">
          <div class="col-span-6">
            <FormKit
              :name="input.label"
              type="date"
              :placeholder="input.placeholder"
              :validation="input.required ? 'required' : ''"
              :min="input.fromDate"
            />
          </div>
        </template>
      </template>

      <button
        type="submit"
        class="btn btn-primary"
      >
        Send
      </button>
    </FormKit>
  </section>
</template>

<style></style>
