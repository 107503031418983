<template>
  <section class="grid-main container">
    <div class="col-span-12 lg:col-span-5 mb-6 lg:mb-0">
      <h2>{{ $text('newsletter-title') }}</h2>
    </div>
    <div class="col-span-12 lg:col-span-7">
      <form
        class="input-wrapper flex border-b-[1px] border-b-[#9E9E9E] pb-3 justify-between"
        @submit.prevent="subscribeToNewsletter"
      >
        <input
          class="w-full mr-4"
          type="email"
          :placeholder="$text('newsletter-input')"
          v-model="email"
        />
        <button
          v-if="!isLoading"
          class="btn secondary-normal"
          type="submit"
        >
          {{ $text('subscribe-button') }}
        </button>
        <svg
          v-else
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-green my-auto"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75 text-green"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </form>
      <div
        class="mt-1"
        v-if="showSuccessMessage"
      >
        <p class="eyebrow text-green fade-in-bottom">{{ $text('newsletter-success-message') }}</p>
      </div>
      <div
        class="mt-1 text-lg"
        v-if="showFailMessage"
      >
        <p class="eyebrow fade-in-bottom">{{ $text('newsletter-fail-message') }}</p>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
// Composables
const { locale } = useI18n();
const { $text } = useSiteSettings();

// Refs
const email = ref<string>();
const showSuccessMessage = ref<boolean>(false);
const showFailMessage = ref<boolean>(false);
const isLoading = ref<boolean>(false);

// Methods
const subscribeToNewsletter = async () => {
  // Reset messages
  showFailMessage.value = false;
  showSuccessMessage.value = false;

  const { value: emailValue } = email;
  const { value: localeValue } = locale;

  // Validate email input
  if (!emailValue) {
    showFailMessage.value = true;
    return;
  }

  try {
    isLoading.value = true;
    // Make API request
    const { data, error } = await useFetch('/api/newsletter', {
      method: 'POST',
      body: {
        email: emailValue,
        lang: localeValue || 'de'
      }
    });

    // Handle potential error
    if (error.value) {
      showFailMessage.value = true;
      return;
    }

    // Show success message
    if (data.value) {
      showSuccessMessage.value = true;
    }
  } catch (err) {
    showFailMessage.value = true;
  } finally {
    isLoading.value = false;
  }
};
</script>

<style scoped lang="scss"></style>
