<template>
  <section class="overview-packages">
    <div v-if="packagesOverview">
      <div
        class="grid-main container"
        id="overview"
      >
        <div class="col-span-12 flex bg-white z-20 py-4 sticky top-0">
          <SeasonSwitchFilter
            class="!ml-0"
            @update:season="(season) => updateList(season)"
          />
        </div>
        <Transition name="slide">
          <masonry-wall
            class="col-span-12 lg:col-start-2 lg:col-end-12 grid-main !gap-x-[200px]"
            :items="elementToDisplay || []"
            :ssr-columns="2"
            :min-columns="1"
            :max-columns="2"
            :gap="100"
          >
            <template #default="{ item, index }">
              <Tile
                :key="index"
                :item="item"
                :parent="parentPackagePage || ''"
              />
            </template>
          </masonry-wall>
        </Transition>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { PackagePreview, PagePreview } from 'types';
import getPackagesQuery from '~/queries/getPackagesQuery';
import MasonryWall from '@yeger/vue-masonry-wall';
import { storeToRefs } from 'pinia';
import { useSettingsStore } from '~/stores/useSettingsStore';

const { $fullpath, $text } = useSiteSettings();

const settingsStore = useSettingsStore();
const cookieSeason = useCookie('season');
const { siteParts } = storeToRefs(settingsStore);
const parentPackagePage = siteParts.value && $fullpath(siteParts.value?.general?.packagePage?._ref);

const sanity = useSanity();
const i18n = useI18n();
const query = getPackagesQuery(i18n.locale.value);
const currentSeason = cookieSeason.value ? cookieSeason.value : 'summer';

const { data: packagesOverview } = await useAsyncData<{
  documents: PackagePreview[];
}>(() => sanity.fetch(query));

const elementToDisplay = ref<PagePreview[]>(packagesOverview.value?.documents || []);

function scrollToFilters() {
  const el = document.getElementById('overview');
  if (el) {
    const stickyHeaderHeight = 0; // sticky header height
    const elementPosition = el.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - stickyHeaderHeight;

    // Delay scrolling for 500 milliseconds
    setTimeout(() => {
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }, 500); // Delay in milliseconds
  }
}

const updateList = (season: 'summer' | 'winter') => {
  scrollToFilters();

  elementToDisplay.value = [
    ...(packagesOverview.value?.documents.filter(
      (item) => season === item.seasonSwitch.season || item.seasonSwitch.season === 'allYear'
    ) || [])
  ];
};

updateList(currentSeason);
</script>

<style lang="postcss">
.overview-packages {
  .masonry-column:first-of-type {
    @apply mt-[100px];
  }

  .masonry-item:not(:last-of-type) {
    @apply lg:mb-[100px] mb-6;
  }

  .slide-enter-active {
    animation: slide-fade-in2 1.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .slide-leave-active {
    animation: slide-fade-out2 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  @keyframes slide-fade-in2 {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-fade-out2 {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-50px);
      opacity: 0;
    }
  }
}
</style>
