<template>
  <section class="overview-rooms">
    <div v-if="roomsOverview">
      <div
        class="grid-main"
        id="overview"
      >
        <!-- Desktop Sticky Room Categories -->
        <div class="col-span-12 hidden md:flex bg-white z-20 sticky top-0 container">
          <p
            @click="showAll()"
            class="mr-4 py-4 cursor-pointer text-[12px] lg:text-[10px] uppercase"
            :class="selectedCategory == null ? 'font-bold' : ''"
          >
            {{ $text('room-category-all') }}
          </p>
          <template
            v-for="(item, index) in getAllCategories()"
            :key="index"
          >
            <p
              @click="filterByCategory(item.value)"
              class="col-span-12 mr-4 py-4 cursor-pointer text-[12px] lg:text-[10px] uppercase"
              :class="item.value === selectedCategory ? 'font-bold' : ''"
            >
              {{ item.label }}
            </p>
          </template>
        </div>

        <!-- Mobile scrollable Room Categories -->
        <div class="md:hidden col-span-12 lg:col-span-6 flex bg-white z-20 sticky top-0">
          <ClientOnly>
            <Swiper
              :breakpoints="{
                0: { slidesPerView: 'auto' }
              }"
              :spaceBetween="50"
              :loop="false"
              ref="mySwiper"
            >
              <SwiperSlide class="lg:mr-0 ml-[theme(grid.gridXMargin)]">
                <p
                  @click="showAll()"
                  class="py-4 cursor-pointer text-[12px] lg:text-[10px] uppercase"
                  :class="selectedCategory == null ? 'font-bold' : ''"
                >
                  All
                </p></SwiperSlide
              >
              <SwiperSlide
                v-for="(item, i) in getAllCategories()"
                :key="i"
                class="lg:mr-0"
                :class="i === getAllCategories().length - 1 ? 'mr-[theme(grid.gridXMargin)]' : ''"
              >
                <p
                  @click="filterByCategory(item.value)"
                  class="py-4 cursor-pointer text-[12px] lg:text-[10px] uppercase"
                  :class="item.value === selectedCategory ? 'font-bold' : ''"
                >
                  {{ item.label }}
                </p>
              </SwiperSlide>
            </Swiper>
          </ClientOnly>
        </div>

        <!-- Mansonry grid -->
        <Transition
          name="slide"
          class="container"
        >
          <masonry-wall
            class="col-span-12 lg:col-start-2 lg:col-end-12 grid-main !gap-x-[200px]"
            :items="elementToDisplay"
            :ssr-columns="2"
            :min-columns="1"
            :max-columns="2"
            :gap="100"
          >
            <template #default="{ item, index }">
              <Tile
                :item="item"
                :parent="parentRoomPage || ''"
              />
            </template>
          </masonry-wall>
        </Transition>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { useSettingsStore } from '~/stores/useSettingsStore';
import { RoomPreview, RoomCategory, PagePreview } from 'types';
import getRoomsQuery from '~/queries/getRoomsQuery';
import { storeToRefs } from 'pinia';

const sanity = useSanity();
const i18n = useI18n();
const query = getRoomsQuery(i18n.locale.value);
const { $fullpath, $text } = useSiteSettings();

const settingsStore = useSettingsStore();
const { siteParts } = storeToRefs(settingsStore);
const parentRoomPage = siteParts.value && $fullpath(siteParts.value?.general?.roomPage?._ref);
const { data: roomsOverview } = await useAsyncData<{
  documents: RoomPreview[];
  roomCategories: RoomCategory[];
}>(() => sanity.fetch(query));

let elementToDisplay = ref<PagePreview[]>(roomsOverview.value?.documents || []);
let selectedCategory = ref<string | null>();

// Update the list with the current selected filter
const filterByCategory = (category: string): void => {
  if (roomsOverview.value) {
    selectedCategory.value = category;
    elementToDisplay.value = [
      ...roomsOverview.value.documents.filter((item) => category === item.category.value)
    ];
    scrollToFilters();
  }
};

function scrollToFilters() {
  const el = document.getElementById('overview');
  if (el) {
    const stickyHeaderHeight = 0; // sticky header height
    const elementPosition = el.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - stickyHeaderHeight;

    // Delay scrolling for 500 milliseconds
    setTimeout(() => {
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }, 500); // Delay in milliseconds
  }
}

const showAll = () => {
  selectedCategory.value = null;
  elementToDisplay.value = [...(roomsOverview.value?.documents || [])];
  scrollToFilters();
};

// Return an array of all categories used
const getAllCategories = (): RoomCategory[] => {
  return roomsOverview.value?.roomCategories || [];
};
</script>

<style lang="postcss">
.overview-rooms {
  .swiper {
    /* @apply translate-x-[20px] md:translate-x-0; */

    .swiper-slide {
      @apply w-fit;
      /* @apply !justify-center !flex; */
    }
  }

  .masonry-column:first-of-type {
    @apply mt-[100px];
  }

  .masonry-item:not(:last-of-type) {
    @apply lg:mb-[100px] mb-6;
  }

  .swiper-slide {
    @apply justify-start;
  }

  .slide-enter-active {
    animation: slide-fade-in2 1.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .slide-leave-active {
    animation: slide-fade-out2 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  @keyframes slide-fade-in2 {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-fade-out2 {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-50px);
      opacity: 0;
    }
  }
}
</style>
