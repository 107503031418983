<template>
  <!-- We load the component based on documentType value -->
  <!-- OverviewRooms, OverviewRestaurants, OverviewPackages, OverviewActivities -->
  <component
    :is="renderOverviewComponent()"
    :documentType="documentType"
  />
</template>

<script setup lang="ts">
// fetch data from Sanity based on the data.documentType.
// documentType can be room, package, restaurant, activity.
const {
  data: { documentType }
} = defineProps<{
  data: {
    documentType: string;
  };
}>();

const renderOverviewComponent = () => {
  const OverviewRooms = resolveComponent('OverviewRooms');
  const OverviewRestaurants = resolveComponent('OverviewRestaurants');
  const OverviewPackages = resolveComponent('OverviewPackages');
  const OverviewActivities = resolveComponent('OverviewActivities');

  switch (documentType) {
    case 'room':
      return OverviewRooms;
    case 'restaurant':
      return OverviewRestaurants;
    case 'package':
      return OverviewPackages;
    case 'activity':
      return OverviewActivities;
    default:
      return null;
  }
};
</script>
