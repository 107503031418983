import { SeasonSwitch } from './../.nuxt/components.d';
import { SanityReference } from '@sanity/client';

export interface Picture {
  alt: string;
  isLazyloaded: boolean;
  refImage: SanityReference;
}

export interface Slide {
  caption: string;
  picture: Picture;
}

export interface Button {
  label: string;
  isExternal: boolean;
  externalLink: string;
  link: SanityReference;
}

export interface FaqEntry {
  title: string;
  text: any;
}

// Section data Props
export interface TextBlockProps {
  _type: string;
  title: string;
  text: Array<any>;
  button: Button;
}

export interface ImageWithCaptionProps {
  caption: string;
  picture: Picture;
}

export interface SlideshowProps {
  list: Slide[];
}

export interface IFrameVideoProps {
  _type: string;
  service: string;
  thumbnail: string;
  videoId: string;
  videoUrl: string;
}

export interface FaqProps {
  list: FaqEntry[];
  title: string;
}

// SANITY SECTIONS:
// The following, are Sanity sections that are passed dynamically to components
export interface ImageWithCaptionSection {
  data: ImageWithCaptionProps;
}

export interface SlideshowSection {
  data: SlideshowProps;
}

export interface TextBlockSection {
  data: TextBlockProps;
}

export interface IFrameVideoSection {
  data: IFrameVideoProps;
}

export interface FaqSection {
  data: FaqProps;
}

export interface Jumplink {
  internalLink: SanityReference;
  sectionId: string;
}

export interface Menu {
  title?: string;
  isJumplink?: boolean;
  jumplink?: Jumplink;
  pageRef?: SanityReference;
  children?: Menu[];
  isExternal: boolean;
  label?: string;
  externalLink?: string;
}

export interface NavigationDocument {
  menuId: string;
  navigation: Menu[];
}

export interface StaticText {
  entry: {
    key: string;
    value: string;
  };
}

export interface SocialMedia {
  type: string;
  url: string;
}

export interface LegalPages {
  conditions: {
    url: SanityUrl;
  };
  imprint: {
    url: SanityUrl;
  };
  privacy: {
    url: SanityUrl;
  };
  cookies: {
    url: SanityUrl;
  };
}

export interface SanityUrl {
  pageSlug: string;
  fullPath: string;
}

export interface SeoMetadata {
  title: string;
  description: string;
  image: any;
  noFollow: boolean;
  noIndex: boolean;
}

export interface SanityPage {
  _id: string;
  __i18n_lang: string;
  title: string;
  isHome: boolean;
  live: boolean;
  url: SanityUrl;
  seoMetadata: SeoMetadata;
  sections: {}[];
  hasParent: boolean;
  parent: SanityReference;
  translations: Translations;
  _createdAt?: string;
  _updatedAt?: string;
}

export interface PageTranslation {
  isHome?: boolean;
  url?: SanityUrl;
  __i18n_lang?: string;
}

export interface Translations {
  de?: PageTranslation;
  fr?: PageTranslation;
  en?: PageTranslation;
}

export interface Partner {
  picture: Picture;
  url: string;
}

export interface Sitepart {
  general: {
    bookingEngineLink: Button;
    secondaryMobileButton: Button;
    eventPage: SanityReference;
    roomPage: SanityReference;
    restaurantPage: SanityReference;
    activityPage: SanityReference;
    packagePage: SanityReference;
    loginPage: SanityReference;
  };
  header: {
    summerPageLink: SanityReference;
    winterPageLink: SanityReference;
    season: 'summer' | 'winter';
    statusBar: {
      isActive: boolean;
    };
  };
  contactInfo: {
    googleMapsLink: string;
    contactText: Array<any>;
    mailUs: Button;
    contactButtonList: {
      button: Button;
      label: string;
    };
  };
  prefooter: {
    summerSeasonLength: string;
    winterSeasonLength: string;
    partners: {
      list: Partner[];
    };
  };
  mediaCorner: {
    sections: { _type: string }[];
    credentials: {
      username: string;
      password: string;
    };
  };
  // footer: {};
}

export interface SiteSettings {
  legal: LegalPages | null;
  socialMedia: SocialMedia[];
  staticText: StaticText[];
  sitemap: SanityPage[];
  navigations: NavigationDocument[];
  siteParts: Sitepart | null;
}

export interface SiteUi {
  isOverlayActive: Boolean | null;
  isFlyOutActive: Boolean | null;
  isWebcamOverlayActive: Boolean | null;
  videoMainIsPlaying: Boolean | null;
}

export interface AppLink {
  to: string;
  label?: string;
  hasLink?: boolean;
  isDropdown?: boolean;
  children?: AppLink[] | boolean;
  isJumplink?: boolean;
  isExternal?: boolean;
  externalLink?: string;
  sectionId?: string;
}

export interface InputField {
  inputType: string;
  label: string;
  placeholder: string;
  required: boolean;
  formItemType: string;
  emailClientValidation: true;
  fromDate?: string;
  options?: string[];
  values?: string[];
}

export interface SanityForm {
  emailAdmin: string;
  adminEmailSubject: string;
  clientEmailContent: string;
  clientEmailSubject: string;
  formLayout: 'one-column' | 'two-columns' | 'three-columns';
  sendgridTemplateId: string;
  inputs: InputField[];
  isRedirectActive?: boolean;
  redirectPage?: SanityReference;
}

export interface FormProps {
  data: SanityForm;
}

export interface CleanDate {
  allDay: boolean;
  endDate: string;
  endTime: string;
  startDate: string;
  startTime: string;
}

export interface Image {
  altText: string;
  caption: string;
  description: string;
  ext: string;
  publicUrlForCrop: string;
}

export interface Item {
  cleanDates?: CleanDate[];
  cleanDate?: CleanDate;
  image: Image[];
  infoLocation: string;
  isTopEvent: boolean;
  listFacetIdAttribute: string[];
  sanityId: string;
  slug: string;
  summary: string;
  textFirstDate: string;
  title: string;
}

export interface FacetAttributes {
  title: string;
  facedId: string;
  facetKeyTypo3: string;
  isActive: boolean;
}

export interface Facet {
  facetAttributes: { [key: string]: FacetAttributes };
  facetDirectory: string;
  facetId: string;
  name: string;
  uid: string;
}

export interface FacetList {
  [key: string]: Facet;
}

export interface Content {
  data: {
    items: Item[];
    facets: FacetList;
  };
}

export interface EventsApi {
  id: number;
  content: Array<Content>;
  title: string;
}

export enum FilterType {
  ONLY_WALDHAUS = 'onlyWaldhausEvents',
  ALL_ACTIVITIES = 'allActivities'
}

export interface RestaurantDocument {
  _id: string;
  __i18n_lang: string;
  title: string;
  detailPageTitle: string;
  live: boolean;
  url: SanityUrl;
  seoMetadata: SeoMetadata;
  content: {
    sections: {}[];
  };
  hasParent: boolean;
  parent: SanityReference;
  translations: Translations;
  settings: {
    hasBackground: boolean;
    pageBackgroundColor: {
      label: string; // don't worry about this
      value: string; // hex color
    };
    description: {}[];
    picture: Picture;
  };
  _createdAt?: string;
  _updatedAt?: string;
}

export interface ButtonItem {
  button: Button;
  style: 'primary' | 'secondary' | 'gold';
}

export interface EventResponse {
  id: number;
  content: ContentItem[];
  title: string;
}

export interface ContentItem {
  data: EventData;
  facets: Facets;
  uriBack: UriBack;
}

export interface EventData {
  item: EventItem;
  sanityId: string;
  summary: string;
  textFirstDate: string;
  title: string;
  uid: number;
  videos: any[];
}

export interface EventItem {
  bookingContact: null | any;
  city: string[];
  cleanDates: CleanDate[];
  contentElements: any[];
  description: string;
  eventContact: null | any;
  eventHotelUrl: string;
  eventUrls: EventUrl[];
  files: any[];
  images: Images;
  infoContact: null | any;
  infoLocation: string;
  isAdditionalContact: boolean;
  isTopEvent: boolean;
  links: any[];
  listRelatedEvents: any[];
  locationText: string;
  organizerContact: null | any;
  otherCoordinates: OtherCoordinates;
  prices: Price[];
  reservationRequired: boolean;
  sanityId: string;
  summary: string;
  textFirstDate: string;
  title: string;
  uid: number;
  videos: any[];
}

export interface EventUrl {
  booking_platform: string;
  isDefault: boolean;
  hasForm: boolean;
  url: string;
}

export interface Images {
  list: ImageItem[];
  primary: any[];
}

export interface ImageItem {
  publicUrlForCrop: string;
  properties: ImageProperties;
}

export interface ImageProperties {
  caption: string;
  title: string;
  alternative: string;
  photographer: string;
  description: string;
  extension: string;
  origin: string;
  season: string;
  crop: any[];
  isPrimary: boolean;
  hotspot: any[];
  dimensions: Dimensions;
}

export interface Dimensions {
  width: number;
  height: number;
}

export interface OtherCoordinates {
  isOtherCoordinates: boolean;
}

export interface Price {
  title: string;
  minPrice: number;
  maxPrice: number;
  priceType: string;
  labelTitlePrice: string;
  labelPrice: null | any;
}

export interface Facets {
  'resort-location': Facet;
  events: Facet;
  languages: Facet;
}

export interface UriBack {
  href: string;
  title: string;
}

export interface EventApiPage {
  _type: string;
  event?: ContentItem;
  seoMetadata?: SeoMetadata;
  url?: {
    fullPath: string;
  };
}

export interface PagePreview {
  url: SanityUrl;
  detailPageSettings: DetailPageSettings;
  category: RoomCategory;
}

interface DetailPageSettings {
  eyebrow?: string;
  previewTitle: string;
  detailPageTitle: string;
  hasBackground?: boolean;
  pageBackgroundColor?: PageBackgroundColor;
  description: any; // blockContent
  slider: Picture[];
  video?: Video;
}

export interface PageBackgroundColor {
  label: string;
  value: string;
}

export interface VideoRef {
  refVideo: SanityReference;
}

export interface Video {
  video: {
    url: string | null;
    mimeType: string | null;
  };
}

export interface RoomCategory {
  __i18n_lang: string;
  label: string;
  value: string;
  orderRank: string;
}

export type RoomPreview = PagePreview;
export type PackagePreview = PagePreview & { seasonSwitch: { season: string } };
export type ActivityPreview = PagePreview & { seasonSwitch: { season: string } };
export type RestaurantPreview = PagePreview;

export interface BottomStroke {
  isActive: boolean;
}
export interface TeaserProps {
  _type: string;
  title: string;
  picture: Picture;
  button: Button;
  isLink: boolean;
  page: SanityReference;
  text: Array<any>;
  alignement: string;
  bottomStroke: {
    isActive: boolean;
  };
}

export interface AccordionTeaser {
  title: string;
  picture: any;
  button: Button;
  text: Array<any>;
  isLink: boolean;
  page: SanityReference;
  backgroundColor: string;
  list: {
    title: string;
    text: Array<any>;
    picture: any;
  }[];
  bottomStroke: {
    isActive: boolean;
  };
}

export interface DownloadList {
  backgroundColor: string;
  list: Resource[];
}

export interface Employee {
  email: string;
  fullname: string;
  picture: Picture;
  role: string;
}

export interface Resource {
  type: 'image' | 'file';
  name: string;
  picture?: {
    upload: {
      url: string;
      originalFilename: string;
    };
  };
  file?: {
    url: string;
    originalFilename: string;
  };
  ref?: {
    picture: Picture; // Assuming `Picture` is a type you've defined elsewhere
  };
}

export interface MenuDownload {
  backgroundColor: string;
  illustration: Picture;
  list: {
    name: string;
    file: { asset: { _ref: string } };
  }[];
}
