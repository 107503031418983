<template>
  <div
    class="sidenav z-[997] relative"
    :class="videoMainIsPlaying ? 'hidden' : ''"
  >
    <div
      @click="toggleNavOverlay"
      class="desktop-menu-button fade-in-left-slow opacity-0 group"
    >
      <div class="-rotate-90 w-fit h-auto overflow-hidden min-w-fit">
        <p
          :class="isOverlayActive ? 'text-white' : ''"
          class="uppercase sidenav-font sticky top-0 whitespace-nowrap select-none group-hover:-translate-y-[101%] transition-all duration-700 inline-block ease-in-dino"
        >
          {{ isOverlayActive ? $text('sidenav-close-menu') : $text('sidenav-menu') }}
        </p>
        <p
          :class="isOverlayActive ? 'text-white' : ''"
          class="uppercase sidenav-font absolute top-0 whitespace-nowrap select-none group-hover:translate-y-[0%] translate-y-[101%] transition-all duration-700 ease-in-dino"
        >
          {{ isOverlayActive ? $text('sidenav-close-menu') : $text('sidenav-menu') }}
        </p>
      </div>
    </div>

    <div
      v-if="!isOverlayActive"
      :class="isOverlayActive ? 'text-white' : ''"
      class="h-screen group top-0 overflow-hidden fixed right-0 w-[theme(grid.gridXMargin)] hidden lg:flex items-center justify-center cursor-pointer fade-in-right-slow opacity-5"
      @click="toggleFlyOutNav"
    >
      <div class="-rotate-90 min-w-[100px] flex justify-center w-fit h-fit overflow-hidden">
        <p
          class="uppercase sidenav-font sticky top-0 select-none group-hover:-translate-y-[101%] transition-all duration-700 inline-block ease-in-dino"
        >
          {{ $text('contact-label') }}
        </p>
        <p
          class="uppercase sidenav-font absolute top-0 group-hover:translate-y-[0%] translate-y-[101%] transition-all duration-700 ease-in-dino"
        >
          {{ $text('contact-label') }}
        </p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useUiStore } from '~/stores/useUiStore';

const { $text } = useSiteSettings();
const isOverlayActive = computed(() => uiStore.ui.isOverlayActive);

const uiStore = useUiStore();
const videoMainIsPlaying = computed(() => uiStore.ui.videoMainIsPlaying);
function toggleNavOverlay() {
  uiStore.toggleOverlay();
}

function toggleFlyOutNav() {
  uiStore.toggleFlyOutNav();
}
</script>

<style lang="postcss">
.desktop-menu-button {
  @apply hidden lg:flex h-screen fixed top-0 left-0 w-[theme(grid.gridXMargin)] items-center justify-center cursor-pointer;
}

.sidenav-font {
  font-size: fluid(10, 12, 640, 1792);
}
</style>
