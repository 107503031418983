<template>
  <section
    class="py-10 text-images-2-columns"
    :class="data.backgroundColor === 'white' ? 'bg-white' : ''"
  >
    <div class="container grid-main relative">
      <!-- Left Column -->
      <div class="col-span-12 lg:col-span-6 mb-10 lg:mb-0">
        <Picture
          v-if="data.leftColumn.picture?.refImage?._ref"
          :alt="data.leftColumn.picture.alt"
          :isLazyloaded="data.leftColumn.picture.isLazyloaded"
          :refImage="data.leftColumn.picture?.refImage?._ref"
          :aspectRatios="[
            { sm: 'square' },
            { md: 'square' },
            { lg: '521:338' },
            { xl: '521:338' },
            { '2xl': '521:338' },
            { '3xl': '521:338' }
          ]"
        />
        <h4 class="py-6 lg:py-8 h3 lg:h4">{{ data.leftColumn.title }}</h4>
        <BoldPortableText
          v-if="data.leftColumn.text"
          :blocks="data.leftColumn.text"
        />
      </div>

      <!-- middle border -->
      <div
        class="hidden lg:block absolute top-0 left-1/2 -translate-x-1/2 w-[0.5px] h-full bg-black"
      />

      <!-- Right Column -->
      <div class="col-span-12 lg:col-span-6">
        <h3 class="mb-8">{{ data.rightColumn.title }}</h3>
        <Picture
          v-if="data.rightColumn.picture?.refImage?._ref"
          :alt="data.rightColumn.picture.alt"
          :isLazyloaded="data.rightColumn.picture.isLazyloaded"
          :refImage="data.rightColumn.picture?.refImage?._ref"
          :aspectRatios="[
            { sm: 'square' },
            { md: 'square' },
            { lg: 'square' },
            { xl: 'square' },
            { '2xl': 'square' },
            { '3xl': 'square' }
          ]"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { PortableText } from '@portabletext/vue';
import { SanityReference } from '@sanity/client';

interface TextImages2ColumnsProps {
  data: {
    backgroundColor: String;
    leftColumn: {
      picture: {
        alt: string;
        isLazyloaded: boolean;
        refImage: SanityReference;
      };
      title: string;
      text: any[];
    };
    rightColumn: {
      picture: {
        alt: string;
        isLazyloaded: boolean;
        refImage: SanityReference;
      };
      title: string;
    };
  };
}

const { data } = defineProps<TextImages2ColumnsProps>();
const { serializers } = useSerializers();
</script>

<style lang="postcss">
.border-after:after {
  @apply w-[0.5px] h-full bg-black;
}
</style>
