<template>
  <template v-if="siteParts">
    <div
      class="right-panel"
      v-for="(item, index) in siteParts?.contactInfo?.contactButtonList"
      :key="index"
    >
      <hr
        v-if="index !== 0"
        class="opacity-20 border-t-[0.6px] text-white"
      />
      <div class="flex justify-between items-start py-3">
        <p class="text-white w-1/3 mt-2 text-[14px] lg:paragraph-1">{{ item.label }}</p>

        <div class="button-wrapper">
          <template v-if="item.hasBookingButton">
            <Button
              class="!w-full"
              :to="bookingButtonUrl"
              :isExternal="siteParts.general?.bookingEngineLink?.isExternal || false"
              :target="siteParts.general?.bookingEngineLink?.isExternal ? '_blank' : ''"
              variant="primary"
              variantType="transparent"
              >{{ siteParts.general?.bookingEngineLink?.label }}
            </Button>
          </template>
          <Button
            class="!w-full"
            :to="
              item.button.isExternal ? item.button.externalLink : $fullpath(item.button.link._ref)
            "
            :isExternal="item.button.isExternal || false"
            :target="item.button.isExternal ? '_blank' : ''"
            variant="primary"
            variantType="transparent"
            >{{ item.button.label }}
          </Button>
        </div>
      </div>
    </div>
  </template>
</template>
<script setup>
import { storeToRefs } from 'pinia';
import { useSettingsStore } from '~/stores/useSettingsStore';
const settingsStore = useSettingsStore();
const { siteParts } = storeToRefs(settingsStore);
const { $fullpath } = useSiteSettings();

const bookingButtonUrl = computed(() => {
  return siteParts.value?.general?.bookingEngineLink?.isExternal
    ? siteParts.value?.general?.bookingEngineLink?.externalLink
    : $fullpath(siteParts.value?.general?.bookingEngineLink?.link._ref || '');
});
</script>

<style lang="postcss">
.button-wrapper {
  @apply flex flex-col w-auto lg:w-1/3;
  & > * {
    @apply mb-1;

    &:only-child,
    &:last-child {
      @apply mb-0;
    }
  }
}
</style>
