<template>
  <section :class="data.backgroundColor === 'white' ? 'bg-white' : ''">
    <div
      class="container flex w-full"
      :class="alignmentClass"
    >
      <AnimationsObserverFadeIn>
        <div
          class="[&>svg]:!max-w-[50vw] [&>svg]:!w-[400px]"
          v-html="data.illustration"
        ></div>
      </AnimationsObserverFadeIn>
    </div>
    <Separator v-if="data.bottomStroke?.isActive" />
  </section>
</template>

<script setup lang="ts">
interface IllustrationProps {
  data: {
    backgroundColor: String;
    bottomStroke: {
      isActive: boolean;
    };
    alignment: string;
    illustration: String;
  };
}

const props = defineProps<IllustrationProps>();

const alignmentClass = computed(() => {
  switch (props.data.alignment) {
    case 'left':
      return 'justify-left';
    case 'right':
      return 'justify-end';
    case 'center':
      return 'justify-center';
    default:
      return 'justify-center'; // default to center if null, undefined, or any other value
  }
});
</script>

<style lang="postcss" scoped></style>
