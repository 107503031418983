<template>
  <section>
    <AnimationsObserverFadeIn>
      <div class="container grid-main lg:max-h-[700px] xl:max-h-max">
        <div
          class="flex flex-col justify-between col-start-1 col-end-13"
          :class="
            data.alignement === 'left'
              ? 'md:col-start-1 md:col-end-5'
              : 'md:col-start-9 md:col-end-13 order-2'
          "
        >
          <NuxtLink
            class="no-underline"
            :to="$fullpath(data?.page?._ref)"
            v-if="data.isLink"
          >
            <ArrowLink
              :text="data.title"
              :tag="'h1'"
            />
          </NuxtLink>
          <h1 v-else>{{ data.title }}</h1>

          <Picture
            v-if="data.picture?.refImage?._ref"
            class="max-h-screen md:hidden my-2"
            :alt="data.picture.alt"
            :isLazyloaded="data.picture.isLazyloaded"
            :refImage="data.picture?.refImage?._ref"
            :aspectRatios="[
              { sm: 'square' },
              { md: 'square' },
              { lg: '867:479' },
              { xl: '867:479' },
              { '2xl': '867:479' },
              { '3xl': '867:479' }
            ]"
          />
          <div>
            <div class="paragraph-1">
              <SanityContent :blocks="data.text" />
            </div>
            <Button
              class="mt-4 w-fit"
              :to="
                data.button.isExternal
                  ? data.button.externalLink
                  : $fullpath(data.button?.link?._ref)
              "
              :isExternal="data.button.isExternal || false"
              :target="data.button.isExternal ? '_blank' : ''"
              variant="primary"
              variantType="normal"
              >{{ data.button.label }}
            </Button>
          </div>
        </div>
        <div
          class="hidden md:flex"
          :class="
            data.alignement === 'left'
              ? 'md:col-start-5 md:col-end-13'
              : 'md:col-start-1 md:col-end-9 order-1'
          "
        >
          <!-- class="max-h-[calc(100vh-(theme(grid.gridXMargin)))]" -->
          <Picture
            v-if="data.picture?.refImage?._ref"
            :alt="data.picture.alt"
            :isLazyloaded="data.picture.isLazyloaded"
            :refImage="data.picture?.refImage?._ref"
            :aspectRatios="[
              { sm: 'square' },
              { md: 'square' },
              { lg: '867:479' },
              { xl: '867:479' },
              { '2xl': '867:479' },
              { '3xl': '867:479' }
            ]"
          />
        </div>
      </div>
      <Separator v-if="data.bottomStroke?.isActive" />
    </AnimationsObserverFadeIn>
  </section>
</template>

<script setup lang="ts">
import { TeaserProps } from '~/types';
const { $fullpath } = useSiteSettings();

interface TeaserSectionProps {
  data: TeaserProps;
}

const { data } = defineProps<TeaserSectionProps>();
</script>
