<template>
  <div
    v-if="data.activateEvents"
    class="container min-h-screen events-section pt-0 pb-8 lg:py-9"
  >
    <div class="grid-main">
      <div
        class="col-span-12"
        v-if="isLoading"
      >
        <p class="text-center animate-pulse">loading...</p>
      </div>
      <!-- Download -->
      <div class="col-span-12 lg:col-span-4">
        <div
          v-if="data.eventFile"
          class="flex justify-between py-1 border-b-[0.5px] mb-10 lg:mb-15 cursor-pointer items-center"
        >
          <SanityFile :asset-id="data.eventFile.asset._ref">
            <template #default="{ src }">
              <a
                class="flex items-center justify-between w-full no-underline hover:translate-x-1 transition-all duration-300 ease-in-dino"
                target="_blank"
                :href="src"
              >
                <p>{{ data.eventFileLabel }} (PDF)</p>
                <span class="download-icon">
                  <svg
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M32.1875 38.1072L32.1875 14"
                      stroke="#333333"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M22 28.0625L32 38.0626L42 28.0625"
                      stroke="#333333"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <line
                      x1="17.75"
                      y1="45.3125"
                      x2="48.25"
                      y2="45.3125"
                      stroke="#333333"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </a>
            </template>
          </SanityFile>
        </div>
      </div>

      <!-- Filters -->
      <div class="col-span-12"></div>

      <!-- Event location -->
      <div class="col-span-12 event-radio">
        <label class="form-control">
          <input
            v-model="regionFilter"
            type="radio"
            name="radio"
            :value="FilterType.ONLY_WALDHAUS"
          />
          {{ $text('event-filter-all') }}
        </label>

        <label class="form-control">
          <input
            v-model="regionFilter"
            type="radio"
            name="radio"
            :value="FilterType.ALL_ACTIVITIES"
          />
          {{ $text('event-filter-all-region') }}
        </label>
      </div>

      <!-- Category Filter -->
      <div class="col-span-12 lg:col-span-5 select">
        <FormKit
          v-model="categoryActiveFilter"
          type="select"
          name="category"
          :options="categoryFilters"
        />
      </div>

      <!-- Month Filter -->
      <div class="col-span-12 lg:col-span-5 select">
        <FormKit
          v-model="monthActiveFilter"
          type="select"
          name="months"
          :options="monthsFilter"
        />
      </div>

      <!-- Submit Button -->
      <div class="col-span-12 lg:col-span-2 select">
        <Button
          :variant="'primary'"
          :variantType="'normal'"
          @click="submitSearch"
          >{{ $text('event-search-button') }}
        </Button>
      </div>
    </div>

    <div id="event-list">
      <!-- Results preview amount -->
      <div
        class="flex mt-8 mb-2 lg:mt-6"
        v-if="searchResultList.length !== 0"
      >
        <Transition
          mode="out-in"
          appear
          name="slide"
        >
          <span
            v-if="searchResultList"
            :key="searchResultList.length"
            class="result-length h4 mr-1"
            >{{ searchResultList.length }}</span
          >
        </Transition>
        <h4>{{ $text('event-result') }}</h4>
      </div>

      <!-- Events -->
      <template v-for="(item, i) in searchResultList">
        <EventItem
          v-if="item"
          :item="item"
        />
      </template>
    </div>

    <!-- No Results -->
    <template v-if="searchResultList && searchResultList?.length === 0">
      <h4 class="mt-10">{{ $text('event-no-results') }}</h4>
    </template>
  </div>
  <div v-else>
    <p>Event Section is deactivated.</p>
  </div>
</template>

<script setup lang="ts">
import { useEventsStore } from '~/stores/useEventsStore';
import { storeToRefs } from 'pinia';
import { FilterType } from '~/types';
import { localeCodes } from '.nuxt/i18n.options.mjs';

const { data } = defineProps<{
  data: {
    activateEvents: boolean;
    eventFileLabel: string;
    eventFile: {
      asset: {
        _ref: string;
      };
    };
  };
}>();

// Store and Composables
const { locale } = useI18n();
const { $text } = useSiteSettings();
const eventsStore = useEventsStore();
const isLoading = ref(true);
await eventsStore.fetchEventsFromApi();
// Async ops
isLoading.value = false;
// const nuxtApp = useNuxtApp();
// const resizeLenis = nuxtApp.$resizeLenis;
// resizeLenis();
const { allEvents, defaultEvents, categoryFilters, monthsFilter } = storeToRefs(eventsStore);

// Refs
const currentMonth = new Date().getMonth() + 1;
const categoryActiveFilter = ref<string | undefined>('');
const regionFilter = ref<FilterType>(FilterType.ONLY_WALDHAUS);
const monthActiveFilter = ref<number | undefined>(currentMonth);
const activeFilters = ref<Array<string | number | undefined>>([]);
const searchResultList = ref<any[]>([...(defaultEvents.value || [])]);

const submitSearch = () => {
  // resetting research
  searchResultList.value = [];
  const isCategoryFilterActive = categoryActiveFilter.value === '';
  const isMonthFilterActive = monthActiveFilter.value === -1;
  const onlyWaldhausEvents = regionFilter.value === FilterType.ONLY_WALDHAUS;

  activeFilters.value = [
    onlyWaldhausEvents ? 'channels-waldhaus-sils-ch' : undefined,
    isCategoryFilterActive ? undefined : categoryActiveFilter.value,
    isMonthFilterActive ? undefined : monthActiveFilter.value
  ].filter((filter) => filter);

  searchResultList.value =
    allEvents.value?.filter((item) => {
      // item has to contain all the activeFilters to be returned
      return activeFilters.value.every((filter) => {
        if (typeof filter === 'number' && item) {
          return new Date(item.cleanDate.startDate).getMonth() + 1 === filter;
        } else {
          return item.listFacetIdAttribute.includes(filter);
        }
      });
    }) || [];
};
</script>

<style lang="postcss">
.events-section {
  .event-line {
    @apply transition-all duration-300;
    &:hover {
      .arrow-event {
        @apply bg-green;

        svg {
          path {
            @apply !stroke-white transition-all duration-700;
          }
        }
      }
    }
  }

  .slide-enter-active {
    animation: slide-fade-in 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .slide-leave-active {
    animation: slide-fade-out 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  @keyframes slide-fade-in {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-fade-out {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-50%);
      opacity: 0;
    }
  }
}

.event-radio {
  @apply mb-4 lg:mb-10 lg:flex lg:items-center;

  .form-control {
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
    @apply mb-4 lg:mb-0 lg:mr-15;
  }

  .form-control:focus-within {
    color: var(--form-control-color);
  }
  input[type='radio'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    -moz-appearance: none;
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--form-background);
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.02em solid currentColor;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }

  input[type='radio']::before {
    content: '';
    width: 0.35em;
    height: 0.35em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }

  input[type='radio']:checked::before {
    transform: scale(1);
  }

  /* input[type='radio']:focus {
    outline: max(2px, 0.15em) solid currentColor;
    outline-offset: max(2px, 0.15em);
  } */
}

.select select.formkit-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
</style>
