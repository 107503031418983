<script setup lang="ts">
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
interface InputProps {
  inputType: string;
  label: string;
  placeholder: string;
  required: boolean;
  formLayout: string;
}

const { inputType, label, placeholder, required } = defineProps<InputProps>();
const emit = defineEmits(['update:phone-number', 'update:input']);
const phoneNumber = ref();
</script>

<template>
  <!-- Maybe turn the col-span-6 into a dynamic prop to pass -->
  <div :class="formLayout">
    <pre>{{ inputType }}</pre>
    <FormKit
      v-if="inputType == 'number' || inputType == 'text' || inputType == 'email'"
      :name="label"
      :type="inputType"
      :placeholder="placeholder"
      :validation="required ? 'required' : ''"
      @update:model-value="emit('update:input', $event)"
    />
    <MazPhoneNumberInput
      v-if="inputType == 'phoneNumber'"
      v-model="phoneNumber"
      color="info"
      defaultCountryCode="CH"
      :preferred-countries="['CH', 'DE', 'FR', 'IT', 'AT']"
      @update="emit('update:phone-number', $event)"
      :success="emit('update:phone-number', $event)"
    />
  </div>
</template>
