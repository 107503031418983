import { onMounted, onBeforeUnmount } from 'vue';

export default function useAnimate(triggering = 'onlyWhenScrollDown', animationReference:any) {
  let observer: IntersectionObserver | null = null;
  let layoutShiftCompensation = 0;

  onMounted(async () => {
    const thresholdArray = (steps: number) =>
      Array(steps + 1)
        .fill(0)
        .map((_, index) => index / steps || 0);

    const obsOptions = {
      threshold: thresholdArray(20)
    };

    observer = new IntersectionObserver(inViewport, obsOptions);

    if(animationReference.value) {
      observer.observe(animationReference.value);
    }else{
        console.error("please pass a ref")
    }
  });

  onBeforeUnmount(() => {
    if (observer) observer.disconnect();
  });

  function inViewport(entries: any[]) {
    let previousY = 0;
    let previousRatio = 0;

    entries.forEach((entry) => {
      const currentY = entry.boundingClientRect.y;
      const currentRatio = entry.intersectionRatio;
      const isIntersecting = entry.isIntersecting;

      if (currentY < previousY) {
        if (currentRatio > previousRatio && isIntersecting) {
          entry.target.classList.add('animation-trigger');
          if (triggering === 'always') {
            entry.target.classList.remove('animation-trigger');
          }
        } else if (triggering === 'always') {
          entry.target.classList.remove('animation-trigger');
        }
      } else if (currentY > previousY) {
        if (currentRatio <= previousRatio && !isIntersecting) {
          if (triggering === 'onlyOnce' && layoutShiftCompensation === 0) {
            entry.target.classList.remove('animation-trigger');
            layoutShiftCompensation++;
          } else if (triggering !== 'onlyOnce') {
            entry.target.classList.remove('animation-trigger');
          }
        } else {
          entry.target.classList.add('animation-trigger');
        }
      }
      previousY = currentY;
      previousRatio = currentRatio;
    });
  }
}
