import { getFile } from '@sanity/asset-utils';

export const useSerializers = () => {
  const {
    public: { sanityProjectId }
  } = useRuntimeConfig();
  const { $fullpath } = useSiteSettings();
  const sanityConfig = {
    dataset: 'production',
    projectId: sanityProjectId
  };

  const serializers = {
    marks: {
      sup: ({ value, text }: { value: {}; text: string }) => {
        return h('sup', text);
      },
      sub: ({ value, text }: { value: {}; text: string }) => {
        return h('sub', text);
      },
      link: ({
        text,
        value
      }: {
        text: string;
        value: { extLink: string; intLink: { _ref: string }; eMail: string; target: boolean };
      }) => {
        const isInternalLink = !!value.intLink;
        const isExternalLink = !!value.extLink;
        const isEmail = !!value.eMail;
        const target = value.target ? '_blank' : '_self';

        if (isExternalLink) {
          return h(
            'a',
            {
              href: value.extLink,
              target
            },
            text
          );
        }

        if (isInternalLink) {
          const _ref = value.intLink._ref;

          return h(
            'a',
            {
              href: `${$fullpath(_ref)}`,
              target: '_self'
            },
            text
          );
        }

        if (isEmail) {
          return h(
            'a',
            {
              href: `mailto:${value.eMail}`,
              target: '_self'
            },
            text
          );
        }
      },
      download: ({
        value,
        text
      }: {
        value: { fileName: string; fileRef: { asset: { _ref: string } } };
        text: string;
      }) => {
        const fileRef = value.fileRef.asset._ref;
        if (!fileRef) {
          return;
        }

        const file = getFile(fileRef, sanityConfig);
        const fileName = value.fileName;
        const href = fileName
          ? `${file.asset.url}?dl=${fileName}.${file.asset.extension}`
          : `${file.asset.url}`;

        return h(
          'a',
          {
            href,
            target: fileName ? '_self' : '_blank'
          },
          text
        );
      }
    }
  };

  return { serializers };
};
