<script setup lang="ts">
const swiper = useSwiper();
</script>

<template>
  <button
    class="absolute top-1/2 -translate-y-1/2 right-0 z-20"
    @click="swiper.slideNext()"
  >
    <svg
      width="30"
      height="28"
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_837_10866"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="30"
        height="28"
      >
        <path d="M29.9905 0H0V28H29.9905V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_837_10866)">
        <path d="M0 14H28.2863" stroke="#191915" stroke-width="2" />
        <path
          d="M15.757 27.5326L29.0385 14.0002L15.757 0.467773"
          stroke="#191915"
          stroke-width="2"
        />
      </g>
    </svg>
  </button>
  <button
    class="absolute top-1/2 -translate-y-1/2 left-0 z-20"
    @click="swiper.slidePrev()"
  >
    <svg
      width="30"
      height="28"
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_837_10872)">
        <mask
          id="mask0_837_10872"
          style="mask-type: luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="30"
          height="28"
        >
          <path
            d="M0.00951958 28L30 28L30 0L0.00952202 -2.62185e-06L0.00951958 28Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_837_10872)">
          <path d="M30 14L1.71374 14" stroke="#191915" stroke-width="2" />
          <path
            d="M14.243 0.46743L0.961545 13.9998L14.243 27.5322"
            stroke="#191915"
            stroke-width="2"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_837_10872">
          <rect
            width="30"
            height="28"
            fill="white"
            transform="translate(30 28) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  </button>
</template>
