<template>
  <section>
    <div class="container grid-main">
      <div class="col-span-12 lg:col-start-3 lg:col-end-11 grid-main border-b-[0.5px]">
        <div class="col-span-12">
          <h4 class="mb-4">{{ $text('media-corner-registration-title') }}</h4>
        </div>
        <!-- Request credential form -->
        <div class="col-span-12 lg:col-span-6">
          <FormKit
            v-model="firstname"
            type="text"
            :placeholder="$text('form-firstname')"
          />
        </div>
        <div class="col-span-12 lg:col-span-6">
          <FormKit
            v-model="surname"
            type="text"
            :placeholder="$text('form-surname')"
          />
        </div>
        <div class="col-span-12">
          <FormKit
            v-model="email"
            type="email"
            :placeholder="$text('form-email')"
          />
        </div>
        <div class="col-span-12 lg:col-span-6 mb-2 lg:mb-0">
          <!-- <FormKit
            v-model="privacyPolicy"
            type="checkbox"
            label="Ich habe die AGBs gelesen"
            :value="false"
          />
          <pre>privacyPolicy: {{ privacyPolicy }}</pre> -->
          <label class="form-control">
            <input
              type="checkbox"
              name="checkbox-checked"
              @change="privacyPolicy = !privacyPolicy"
            />
            {{ $text('form-privacy-policy') }}
          </label>
        </div>
        <div class="col-span-12 lg:col-span-6 flex lg:justify-end">
          <button
            :class="!isCredentialRequestFormValid ? 'opacity-30' : ''"
            class="btn primary-normal"
            :disabled="!isCredentialRequestFormValid"
            @click="sendCredentialsRequest"
          >
            {{ $text('media-corner-registration-submit-button') }}
            <svg
              v-if="isLoading"
              class="animate-spin ml-2 h-3 w-3 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </button>
        </div>
        <vue-turnstile
          class="col-span-12 my-2"
          site-key="0x4AAAAAAAfLRYfB3HIbznx_"
          v-model="turnstileToken"
          :language="locale"
        />
        <div class="col-span-12 pb-10">
          <p
            class="mt-4"
            v-if="showCredentialsMessage"
          >
            {{ $text('form-credentials-sent') }}
          </p>
          <p v-if="showErrorMessage">An error occured, please contact the admin of the website.</p>
        </div>
      </div>

      <div class="col-span-12 lg:col-start-3 lg:col-end-11 grid-main pt-10">
        <div class="col-span-12">
          <h4 class="mb-4">{{ $text('media-corner-login-title') }}</h4>
        </div>
        <!-- Request credential form -->
        <div class="col-span-12 lg:col-span-6">
          <FormKit
            v-model="username"
            type="text"
            :placeholder="$text('username')"
          />
        </div>
        <div class="col-span-12 lg:col-span-6">
          <FormKit
            v-model="password"
            type="password"
            :placeholder="$text('password')"
          />
        </div>
        <div class="col-span-12 lg:col-span-6"></div>
        <div class="col-span-12 flex justify-end">
          <button
            :class="!isLoginFormValid ? 'opacity-30' : ''"
            class="btn primary-normal"
            :disabled="!isLoginFormValid"
            @click="login"
          >
            {{ $text('login-button') }}
          </button>
        </div>
        <div class="col-span-12 pb-10">
          <p v-if="showLoginErrorMessage">Username or password are not valid.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useSettingsStore } from '~/stores/useSettingsStore';
const { locale } = useI18n();

// Stores
const { siteParts } = storeToRefs(useSettingsStore());

// Composables
const { $text } = useSiteSettings();
const router = useRouter();

// Refs
const firstname = ref('');
const surname = ref('');
const email = ref('');
const privacyPolicy = ref(false);
const showCredentialsMessage = ref(false);
const showLoginErrorMessage = ref(false);
const isLoading = ref(false);
const showErrorMessage = ref(false);

const username = ref('');
const password = ref('');
const turnstileToken = ref('');

// Computed
const isCredentialRequestFormValid = computed(() => {
  return firstname.value && surname.value && email.value && privacyPolicy.value;
});
const isLoginFormValid = computed(() => {
  return username.value && password.value;
});

// Methods
const login = () => {
  if (
    username.value === siteParts.value?.mediaCorner.credentials.username &&
    password.value === siteParts.value?.mediaCorner.credentials.password
  ) {
    const cookie = useCookie('media-corner');
    cookie.value = true;
    router.push('/media-corner');
  } else {
    showLoginErrorMessage.value = true;
  }
};

async function sendCredentialsRequest() {
  isLoading.value = true;
  try {
    const response = await fetch('/api/credentials', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        firstname: firstname.value,
        surname: surname.value,
        email: email.value,
        turnstileToken: turnstileToken.value
      })
    });

    const data = await response.json();
    console.log('return:', data);

    if (data.status === 200) {
      showCredentialsMessage.value = true;
      showErrorMessage.value = false;
      firstname.value = '';
      surname.value = '';
      email.value = '';
    } else {
      showCredentialsMessage.value = false;
      showErrorMessage.value = true;
      throw new Error(data.error || 'Failed to send credentials');
    }
  } catch (error) {
    showCredentialsMessage.value = false;
    showErrorMessage.value = true;
    console.error('Error sending credentials:', error.message);
  } finally {
    isLoading.value = false;
  }
}
</script>

<style style="postcss" scoped>
/* #privacy-policy {
  div[data-type='checkbox'] {
    .formkit-inner {
      width: 40px;
    }
  }
} */
.form-control {
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.form-control + .form-control {
  margin-top: 1em;
}

.form-control--disabled {
  color: var(--form-control-disabled);
  cursor: not-allowed;
}

input[type='checkbox'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.05em solid currentColor;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type='checkbox']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type='checkbox']:checked::before {
  transform: scale(1);
}

input[type='checkbox']:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}
</style>
