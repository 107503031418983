<template>
  <section class="container flex justify-center">
    <div
      class="mx-auto webcam flex justify-center"
      id="roundshot-thumbnail-503f5b09b092de6121fba59a9c80ab75"
    ></div>
  </section>
</template>

<script setup>
onMounted(() => {
  const script = document.createElement('script');
  script.src = 'https://backend.roundshot.com/js/roundshot_animated_thumbnail.js';
  script.async = true;
  // append script to the document head
  document.head.appendChild(script);

  setTimeout(() => {
    window.roundshot.animate({
      id: '503f5b09b092de6121fba59a9c80ab75',
      picture: 'https://backend.roundshot.com/cams/503f5b09b092de6121fba59a9c80ab75/thumbnail',
      link: 'https://sils.roundshot.com/',
      angle: 360,
      width: 1600,
      height: 400,
      bounce: false,
      open_in_new_tab: true,
      speed: 17
    });
  }, 500);
});
</script>

<style lang="postcss">
.rs-img-503f5b09b092de6121fba59a9c80ab75 {
  @apply !my-auto !mx-auto;
}

.webcam {
  & > div {
    @apply scale-[4] lg:scale-[2.2] !h-[250px]  md:!h-[400px];
  }
  img {
    object-fit: contain !important;
  }
}

#roundshot-thumbnail-503f5b09b092de6121fba59a9c80ab75 {
  @apply w-[95%] overflow-hidden;
}
</style>
