<template>
  <section class="overview-restaurant">
    <div v-if="restaurantOverview">
      <div
        class="grid-main container"
        id="overview"
      >
        <masonry-wall
          class="col-span-12 lg:col-start-2 lg:col-end-12 grid-main !gap-x-[200px]"
          :items="restaurantOverview.documents"
          :ssr-columns="2"
          :min-columns="1"
          :max-columns="2"
          :gap="100"
        >
          <template #default="{ item, index }">
            <Tile
              :key="index"
              :item="item"
              :parent="parentRestaurantPage || ''"
            />
          </template>
        </masonry-wall>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { RestaurantPreview } from 'types';
import getRestaurantsQuery from '~/queries/getRestaurantsQuery';
import MasonryWall from '@yeger/vue-masonry-wall';
import { storeToRefs } from 'pinia';
import { useSettingsStore } from '~/stores/useSettingsStore';

const { $fullpath } = useSiteSettings();
const settingsStore = useSettingsStore();
const sanity = useSanity();
const i18n = useI18n();
const query = getRestaurantsQuery(i18n.locale.value);
const { siteParts } = storeToRefs(settingsStore);
const parentRestaurantPage =
  siteParts.value && $fullpath(siteParts.value?.general?.restaurantPage?._ref);

const { data: restaurantOverview } = await useAsyncData<{
  documents: RestaurantPreview[];
}>(() => sanity.fetch(query));
</script>

<style lang="postcss">
.overview-restaurant {
  .masonry-column:first-of-type {
    @apply mt-[100px];
  }

  .masonry-item:not(:last-of-type) {
    @apply lg:mb-[100px] mb-6;
  }
}
</style>
