export default function getRestaurantsQuery(locale: string) {
  const query = `live==true && _type=="restaurant" && __i18n_lang=="${locale}" && !(_id in path("drafts.**"))`;

  return groq`{
    'documents': *[${query}] | order(orderRank asc) {
      detailPageSettings,
      category->,
      url,
      orderRank,
    }
  }`;
}
