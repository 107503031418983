<template>
  <section
    class="carousel"
    :class="data.backgroundColor === 'white' ? 'bg-white py-8 lg:py-12 mb-0' : ''"
  >
    <AnimationsObserverFadeIn>
      <div class="container grid-main">
        <!-- Text content -->
        <NuxtLink
          class="col-start-1 col-end-13 uppercase no-underline mb-4"
          :class="data.text ? 'lg:col-end-6' : 'lg:col-end-9'"
          :to="$fullpath(data?.page?._ref)"
          v-if="data.isLink"
        >
          <ArrowLink
            :text="data.title"
            :tag="'h1'"
          />
        </NuxtLink>
        <h1
          class="col-start-1 col-end-13 lg:col-end-6 uppercase mb-4"
          v-else
        >
          {{ data.title }}
        </h1>

        <p class="col-start-1 lg:col-start-9 col-end-13 mb-4 lg:mb-8">
          <SanityContent :blocks="data.text" />
        </p>

        <!-- Swiper -->
        <div
          class="col-span-12 lg:min-h-[600px]"
          v-if="carouselList.length > 3 || isMobile"
        >
          <Swiper
            :keyboard="{
              enabled: true
            }"
            :breakpoints="{
              0: { slidesPerView: 1.25 },
              1024: { slidesPerView: 3.1 }
            }"
            :loop="false"
            :modules="[SwiperPagination]"
            :pagination="{
              el: '.swiper-pagination',
              type: 'progressbar'
            }"
            ref="mySwiper"
          >
            <SwiperSlide
              v-for="(item, i) in carouselList"
              class="pr-4"
              :key="i"
            >
              <NuxtLink
                v-if="item.detailPageSettings"
                :to="`${$fullpath(item._id)}`"
                class="w-full no-underline group"
              >
                <Picture
                  v-if="item?.detailPageSettings?.slider?.length > 0"
                  class="mb-1 aspect-[3/4]"
                  :alt="item.detailPageSettings?.slider[0]?.alt"
                  :isLazyloaded="false"
                  :refImage="item.detailPageSettings?.slider[0]?.refImage._ref"
                  :aspectRatios="[
                    { sm: '3:4' },
                    { md: '3:4' },
                    { lg: '3:4' },
                    { xl: '3:4' },
                    { '2xl': '3:4' },
                    { '3xl': '3:4' }
                  ]"
                />
                <Video
                  v-else-if="item?.detailPageSettings?.video"
                  class="mb-1 aspect-[3/4]"
                  :video="item.detailPageSettings.video"
                />
                <div class="">
                  <p class="eyebrow mt-1">
                    {{ item.detailPageSettings?.eyebrow || ' ' }}
                  </p>
                  <h4 class="uppercase mb-1">
                    {{ item.detailPageSettings?.previewTitle || ' ' }}
                  </h4>
                  <p class="paragraph-1 mb-9">
                    <SanityContent :blocks="item.detailPageSettings?.description" />
                  </p>
                </div>
              </NuxtLink>
            </SwiperSlide>

            <div class="relative w-full">
              <div class="swiper-pagination swiper-pagination-bullets m-auto bottom-0"></div>
            </div>
          </Swiper>
        </div>

        <!-- Not Swiper -->
        <div
          class="col-span-12 grid-main"
          v-else
        >
          <NuxtLink
            :to="`${$fullpath(item._id)}`"
            class="md:col-span-4 col-span-6 no-underline group grid"
            v-for="(item, index) in carouselList"
            :key="index"
          >
            <Picture
              v-if="
                item?.detailPageSettings?.slider &&
                item?.detailPageSettings?.slider[0]?.refImage?._ref
              "
              class="mb-1 aspect-[3/4]"
              :alt="item.detailPageSettings?.slider[0]?.alt"
              :isLazyloaded="false"
              :refImage="item.detailPageSettings?.slider[0].refImage._ref"
              :aspectRatios="[
                { sm: '3:4' },
                { md: '3:4' },
                { lg: '3:4' },
                { xl: '3:4' },
                { '2xl': '3:4' },
                { '3xl': '3:4' }
              ]"
            />
            <Video
              v-else-if="item?.detailPageSettings?.video"
              class="mb-1 aspect-[3/4]"
              :video="item.detailPageSettings.video"
            />
            <div class="">
              <p class="eyebrow mt-1">{{ item.detailPageSettings?.eyebrow }}</p>
              <h4 class="uppercase mb-1">{{ item.detailPageSettings?.previewTitle }}</h4>
              <p class="paragraph-1">
                <SanityContent :blocks="item.detailPageSettings?.description" />
              </p>
            </div>
          </NuxtLink>
        </div>
      </div>
      <Separator
        class="hidden md:block"
        v-if="carouselList.length <= 3"
      />
    </AnimationsObserverFadeIn>
  </section>
</template>

<script setup lang="ts">
import { SanityReference } from '@sanity/client';

interface CarouselProps {
  data: {
    backgroundColor: string;
    title: string;
    page: SanityReference;
    isLink: boolean;
    text: any[];
    documentType: 'room' | 'activity' | 'restaurant' | 'package';
    room_carouselList?: any[];
    activity_carouselList?: any[];
    package_carouselList?: any[];
    restaurant_carouselList?: any[];
  };
}

const { data } = defineProps<CarouselProps>();

const { $fullpath } = useSiteSettings();

const carouselList = computed(() => {
  return data[`${data.documentType}_carouselList`] || [];
});

const isMobile = ref<boolean>(false);

const checkMobile = () => {
  isMobile.value = window.innerWidth < 768;
};

onMounted(() => {
  window.addEventListener('resize', checkMobile);
  if (window.innerWidth < 768) {
    isMobile.value = true;
  }
});

onUnmounted(() => {
  window.removeEventListener('resize', checkMobile);
});
</script>

<style lang="postcss">
.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.15);
  @apply !h-[2px];
}

.carousel {
  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    @apply bg-greenDark;
  }
}
</style>
