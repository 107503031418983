<template>
  <div
    id="flyout"
    class="fixed top-0"
  >
    <div
      class="fixed top-0 right-0 w-full lg:w-flyOutWidth h-full z-[10] overlay"
      :class="[isFlyOutActive ? 'pointer-events-auto animation-show' : 'pointer-events-none']"
    >
      <!-- Contact Column -->
      <div class="relative flex flex-col z-10 w-full h-full bg-red overflow-auto bg-greenDark">
        <!-- Close icon -->
        <div
          class="close-overlay"
          @click="closeFlyOutNav"
        >
          <SVGLoader
            class="hidden lg:flex w-6 h-6"
            name="close"
          />
          <span class="close-text">{{ $text('contact-overlay-close') }}</span>
        </div>
        <div class="overflow-scroll h-[102vh]">
          <!-- Contact info -->
          <div class="bg-green pt-10 pb-8 lg:py-10 px-[theme(grid.gridXMargin)]">
            <div class="w-full lg:flex lg:justify-between gap-8">
              <div class="w-full lg:w-2/3">
                <h4 class="text-3xl text-white uppercase mb-6">
                  {{ $text('contact-overlay-title') }}
                </h4>
                <MenuAddressBlock theme="light" />
              </div>
              <div class="w-full lg:w-1/3 lg:self-end">
                <Button
                  class="mt-4 lg:mt-0 w-fit lg:!w-full"
                  :to="siteParts?.contactInfo?.mailUs?.externalLink"
                  :isExternal="true"
                  :target="'_blank'"
                  variant="primary"
                  variantType="transparent"
                  >{{ siteParts?.contactInfo?.mailUs?.label }}
                </Button>
              </div>
            </div>
          </div>

          <!-- Telephone numbers -->
          <div class="bg-greenDark pb-10 pt-4 lg:py-8 px-[theme(grid.gridXMargin)]">
            <MenuContactList />
          </div>
        </div>
      </div>
    </div>

    <!-- Black overlay -->
    <div
      class="fixed top-0 left-0 w-full h-full pointer-events-none bg-black z-[5] overlay-bg"
      :class="[isFlyOutActive ? 'pointer-events-auto overlay-bg-show' : '']"
      @click="closeFlyOutNav"
    />
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useSettingsStore } from '~/stores/useSettingsStore';
import { computed, watch, onBeforeMount, onBeforeUnmount } from 'vue';
import { useUiStore } from '~/stores/useUiStore';

// load $text
const { $text } = useSiteSettings();
const { siteParts } = storeToRefs(useSettingsStore());
const uiStore = useUiStore();
const route = useRoute();

// Computed properties
const isFlyOutActive = computed(() => uiStore.ui.isFlyOutActive);

// Watchers
watch(isFlyOutActive, (isActive) => {
  document.documentElement.classList[isActive ? 'add' : 'remove']('no-scroll');
});

watch(
  () => route.path,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      closeFlyOutNav();
    }
  }
);

// Methods
const closeFlyOutNav = () => {
  uiStore.closeFlyOutNav();
};

const closeFlyOutNavEsc = (e) => {
  if (isFlyOutActive.value && e.keyCode === 27) {
    uiStore.closeFlyOutNav();
  }
};

// Lifecycle hooks
onBeforeMount(() => {
  document.addEventListener('keyup', closeFlyOutNavEsc);
});

onBeforeUnmount(() => {
  document.removeEventListener('keyup', closeFlyOutNavEsc);
});
</script>

<style scoped lang="postcss">
nav {
  ul {
    @apply border-b p-0 m-0;

    li {
      @apply border-t;
    }

    ul {
      @apply pl-[40px] md:pl-[50px] lg:pl-[60px] border-none;
    }

    a {
      @apply text-white hover:text-gray-300 no-underline transition
        block py-[10px] md:py-[15px] lg:py-[20px];

      &.nuxt-link-exact-active {
        @apply font-bold;
      }
    }
  }
}

.svg-close {
  @apply top-[30px] right-[theme(grid.gridXMargin)];
}

.overlay {
  transform: translateX(100%);
  opacity: 1;
  transition: all 0.3s;
}

.overlay-bg {
  opacity: 0;
}

.overlay-bg-show {
  opacity: 0.3;
  transition: all 0.6s;
}

.animation-show {
  transform: translateX(0%);
  opacity: 1;
  transition: all 0.3s;
}

#flyout {
  .close-overlay {
    @apply block absolute top-4 right-4 lg:top-2 lg:right-2 z-10 cursor-pointer;
    left: unset;
  }
}

.close-text {
  @apply block lg:hidden text-[10px] text-white uppercase;
  letter-spacing: 1.3px;
  font-weight: 461;
}
</style>
