<template>
  <section class="overview-activities">
    <div v-if="activitiesOverview">
      <div
        class="grid-main container"
        id="overview"
      >
        <div class="col-span-12 flex bg-white z-20 py-4 sticky top-0">
          <SeasonSwitchFilter
            class="!ml-0"
            @update:season="(season) => toggleWeather(season)"
          />
        </div>
        <Transition name="slide">
          <masonry-wall
            class="col-span-12 lg:col-start-2 lg:col-end-12 grid-main !gap-x-[200px]"
            :items="elementToDisplay || []"
            :ssr-columns="2"
            :min-columns="1"
            :max-columns="2"
            :gap="100"
          >
            <template #default="{ item, index }">
              <Tile
                :key="index"
                :item="item"
                :parent="parentActivityPage || ''"
              />
            </template>
          </masonry-wall>
        </Transition>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { ActivityPreview } from 'types';
import getActivitiesQuery from '~/queries/getActivitiesQuery';
import MasonryWall from '@yeger/vue-masonry-wall';
import { storeToRefs } from 'pinia';
import { useSettingsStore } from '~/stores/useSettingsStore';

const { $fullpath } = useSiteSettings();

const settingsStore = useSettingsStore();

const cookieSeason = useCookie('season');
const sanity = useSanity();
const i18n = useI18n();
const query = getActivitiesQuery(i18n.locale.value);
const { siteParts } = storeToRefs(settingsStore);
const parentActivityPage =
  siteParts.value && $fullpath(siteParts.value?.general?.activityPage?._ref);
const currentSeason = ref(cookieSeason.value);

onMounted(() => {
  currentSeason.value = cookieSeason.value;
  updateList(currentSeason.value);
});

const { data: activitiesOverview } = await useAsyncData<{
  documents: ActivityPreview[];
}>(() => sanity.fetch(query));
const elementToDisplay = ref(activitiesOverview.value?.documents);

function scrollToFilters() {
  const el = document.getElementById('overview');
  if (el) {
    const stickyHeaderHeight = 0; // sticky header height
    const elementPosition = el.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - stickyHeaderHeight;

    // Delay scrolling for 500 milliseconds
    setTimeout(() => {
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }, 500); // Delay in milliseconds
  }
}

const toggleWeather = (season: 'summer' | 'winter') => {
  updateList(season);
  scrollToFilters();
};

const updateList = (season: 'summer' | 'winter') => {
  elementToDisplay.value = activitiesOverview.value?.documents.filter(
    (item) => season === item.seasonSwitch?.season || item.seasonSwitch?.season === 'allYear'
  );
};
</script>

<style lang="postcss">
.overview-activities {
  .masonry-column:first-of-type {
    @apply mt-[100px];
  }

  .masonry-item:not(:last-of-type) {
    @apply lg:mb-[100px] mb-6;
  }
}
</style>
