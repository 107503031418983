<template>
  <section class="container grid-main">
    <div
      v-for="(item, index) in allEmployees"
      :key="index"
      class="col-span-12 lg:col-span-4 mb-8 flex flex-col justify-between"
    >
      <div>
        <Picture
          :alt="item.picture.alt"
          :isLazyloaded="item.picture.isLazyloaded"
          :refImage="item.picture.refImage._ref"
          :aspectRatios="[
            { sm: '405:489' },
            { md: '405:489' },
            { lg: '405:489' },
            { xl: '405:489' },
            { '2xl': '405:489' },
            { '3xl': '405:489' }
          ]"
        />
        <div>
          <h4 class="uppercase mt-2 h2 lg:h4">{{ item.fullname }}</h4>
          <p class="mt-2">{{ item.role }}</p>
        </div>
      </div>
      <!-- Divider -->
      <div class="divider right mt-2 h-[23px] flex flex-col items-center">
        <hr />
        <NuxtLink
          v-if="item.email"
          :to="'mailto:' + item.email"
        >
          <ArrowLink
            class="arrowLink"
            :text="'email'"
            :tag="'span'"
          />
        </NuxtLink>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { Employee } from '~/types';
import getAllEmployees from '../../queries/getAllEmployees';

const sanity = useSanity();
const { locale } = useI18n();
const query = getAllEmployees(locale.value);
const { data: allEmployees } = await useAsyncData<Employee[]>(() => sanity.fetch(query));
</script>

<style>
.divider {
  display: flex;
  flex-direction: row !important;
  flex-flow: row;
  width: 100%;
}

.divider a {
  align-self: baseline;
  flex-grow: 2;
  white-space: nowrap;
  text-transform: capitalize;
  font-size: 14px;
  text-decoration: none;
}

.divider hr {
  margin-top: 12px;
  width: 100%;
  border: 0;
  height: 0.5px;
  background: black;
}

.arrowLink {
  font-size: 14px !important;
}
.divider.right a {
  padding-left: 15px;
}
</style>
