<template>
  <section
    class="relative !mt-0 pt-0"
    :class="showFullScreenVideo ? 'z-10' : ''"
  >
    <!-- Patch for Ios fullscreen -->
    <div
      class="fixed top-0 left-0 bg-white w-screen h-[100dvh] z-[999]"
      v-if="showFullScreenVideo && isIOS()"
    >
      <Video
        ref="video"
        :key="showFullScreenVideo + 'k'"
        :muted="true"
        :controls="showFullScreenVideo ? true : false"
        :video="data.fullLengthVideo"
        :id="'fullScreenVideo'"
        class="w-full h-full flex"
      />
      <button
        @click="showFullScreenVideo = false"
        class="absolute bottom-4 left-1/2 -translate-x-1/2 bg-white rounded-full"
        :arrowRight="false"
        :arrowLeft="false"
        :variant="'primary'"
        :variantType="'color'"
      >
        <SVGLoader
          class="w-7 h-7"
          name="closeBlack"
        />
      </button>
    </div>
    <!-- END: Patch for Ios fullscreen -->

    <div class="container relative fade-in-bottom-slow">
      <!-- <Transition name="fade"> -->
      <!-- <Video
        v-if="showFullScreenVideo"
        :controls="true"
        :video="data.fullLengthVideo"
        :id="'fullScreenVideo'"
      /> -->
      <!-- </Transition> -->
      <Video
        ref="video"
        :key="showFullScreenVideo + 'k'"
        :muted="true"
        :controls="showFullScreenVideo ? true : false"
        :video="showFullScreenVideo ? data.fullLengthVideo : data.desktopVideoShort"
        :id="'fullScreenVideo'"
        class="w-full h-[calc(100vh-(100px)-(theme(grid.gridXMargin)))] md:flex hidden"
      />

      <!-- Button -->
      <div
        @click="toggleFullScreenVideo"
        class="play-button absolute"
      >
        <SVGLoader
          class="mr-1"
          name="playicon"
        />
        {{ $text('video-hero-play')?.toUpperCase() }}
      </div>

      <div class="absolute bottom-3 left-1/2 -translate-x-1/2">
        <p class="uppercase text-white text-[10px] font-[461]">The waldhaus sils</p>
        <hr class="border-l-[1px] border-t-0 h-5 border-white w-[1px] mx-auto mt-1" />
      </div>
      <Video
        ref="video"
        :muted="true"
        :controls="false"
        :video="data.mobileVideoShort"
        class="w-full h-[calc(100dvh-(100px)-(theme(grid.gridXMargin)))] flex md:hidden"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import { useUiStore } from '~/stores/useUiStore';

interface Reference {
  _ref: string;
  _type: string;
}

interface Video {
  _type: string;
  refVideo: Reference;
}

interface VideoHeroProps {
  _type: string;
  _key: string;
  mobileVideoShort: Video;
  desktopVideoShort: Video;
  fullLengthVideo: Video;
}

interface VideoHeroSection {
  data: VideoHeroProps;
}

defineProps<VideoHeroSection>();

const uiStore = useUiStore();
// const videoMainIsPlaying = ref<boolean>(false);
const { $text } = useSiteSettings();
const showFullScreenVideo = ref<boolean>(false);

// Watchers to blcok the scrool on Ios when fixed div is shown
watch(showFullScreenVideo, (isActive) => {
  if (isIOS()) {
    document.documentElement.classList[isActive ? 'add' : 'remove']('no-scroll');
  }
});

const toggleFullScreenVideo = () => {
  showFullScreenVideo.value = !showFullScreenVideo.value;

  setTimeout(() => {
    const fullScreenVideo = document.getElementById('fullScreenVideo');
    if (showFullScreenVideo.value) {
      // Enter fullscreen mode
      if (fullScreenVideo.requestFullscreen) {
        fullScreenVideo.requestFullscreen();
      } else if (fullScreenVideo.webkitRequestFullscreen) {
        // Safari
        fullScreenVideo.webkitRequestFullscreen();
        // fullScreenVideo.webkitEnterFullscreen();
      } else if (fullScreenVideo.msRequestFullscreen) {
        // IE11
        fullScreenVideo.msRequestFullscreen();
      }
    } else {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        // Safari
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // IE11
        document.msExitFullscreen();
      }
    }
  }, 100);
};

function isIOS() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const platform = window.navigator.platform.toLowerCase();

  // Check platforms that are known to be iOS devices
  const iosPlatforms = ['iphone', 'ipad', 'ipod', 'ipod touch'];

  // Check both the platform and the user agent string to cover more cases
  return (
    iosPlatforms.includes(platform) ||
    userAgent.includes('iphone') ||
    userAgent.includes('ipad') ||
    userAgent.includes('ipod')
  );
}

onMounted(() => {
  document.addEventListener('fullscreenchange', function () {
    if (document.fullscreenElement !== null) {
      console.log('Entering Full Screen Toggle');
    } else {
      showFullScreenVideo.value = false;
    }
  });
});
</script>

<style lang="postcss">
.fade-enter-active,
.fade-leave-active {
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
}

.icon-control {
  path {
    pointer-events: none;
  }
  &:hover {
    path {
      @apply fill-black;
    }
  }
}

.fade-enter-from,
.fade-leave-to {
  @apply scale-x-95 scale-y-90 opacity-0;
}

.animate-video-out {
  @apply scale-0;
}

.animate-video-preview-out {
  @apply scale-0 transition-all duration-1000;
}

/* .fullscreen-video {
  @apply absolute top-0 w-screen h-screen z-[998] -translate-x-[calc(theme(grid.gridXMargin))] -translate-y-[100px];
} */

.play-button {
  @apply items-center icon-control flex bottom-6 md:bottom-8 z-[999] left-1/2 -translate-x-1/2 md:translate-x-0 md:left-auto md:right-6 lg:right-10 text-white rounded-full py-2 px-4 md:py-1 md:px-3 border-[0.5px] border-white text-[10px] font-[461] hover:text-black hover:bg-white transition-all duration-200 cursor-pointer;
}
</style>
