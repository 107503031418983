<template>
  <section class="container grid-main lead-with-image">
    <div class="col-span-12 lg:col-span-6 mb-4 lg:mb-0 flex items-end">
      <div class="richtext lead left-text">
        <PortableText
          :value="data.leadText"
          :serializers="serializers"
        />
      </div>
    </div>
    <div class="col-span-12 lg:col-span-6 flex items-end">
      <Picture
        v-if="data.picture.refImage"
        :alt="data.picture.alt"
        :isLazyloaded="data.picture.isLazyloaded"
        :refImage="data.picture.refImage._ref"
        :aspectRatios="[
          { sm: '16:5' },
          { md: '16:5' },
          { lg: '16:5' },
          { xl: '16:5' },
          { '2xl': '16:5' },
          { '3xl': '16:5' }
        ]"
      />
      <Video
        v-else-if="data.video"
        :video="data.video"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import { PortableText } from '@portabletext/vue';
import { SanityReference } from '@sanity/client';

interface LeadWithImageProps {
  data: {
    picture: {
      alt: string;
      isLazyloaded: boolean;
      refImage: SanityReference;
    };
    video: {
      video: {
        url: string | null;
        mimeType: string | null;
      };
    };
    leadText: any[];
  };
}
const { data } = defineProps<LeadWithImageProps>();
const { serializers } = useSerializers();
</script>

<style lang="postcss">
.lead-with-image {
  .left-text {
    p {
      @apply !mb-0;
    }
  }
}
</style>
